/* eslint-disable */

import { LocalStorage } from './storage';
import { PubSub } from './mini-pub-sub';
import type { AuthState, StoredCallbackState, Token } from './types';
import { environment } from '../../../environment';
import { pdebounce } from './promise';

const AUTH_PARAMS = ['state', 'code', 'impersonation'];
function trimAuthParams (urlString: string) {
  const url = new URL(urlString);
  AUTH_PARAMS.forEach(p => url.searchParams.delete(p));
  return url.toString();
}
export class OauthClientError extends Error {}
type BackendTokenJSON = { access_token: string, refresh_token: string, expires_in: number };
const onTokenUpdateSymbol = {}; // TODO waiting on https://github.com/tc39/proposal-symbols-as-weakmap-keys to use Symbol('onTokenUpdateSymbol');
const onBeforeLoginSymbol = {}; // TODO waiting on https://github.com/tc39/proposal-symbols-as-weakmap-keys to use Symbol('onTokenUpdateSymbol');
export class OauthClient {
  clientId: string;
  // @ts-ignore
  _storage: LocalStorage;

  // auth state
  // @ts-ignore
  _oauthState: AuthState|null = null;
  token: null | Token = null;

  // events
  // @ts-ignore
  _pubsub = new PubSub();

  constructor ({ clientId }) {
    this.clientId = clientId;
  }

  async init ({ redirectUri } = {} as { redirectUri?: string }) {
    this._storage = new LocalStorage();
    try {
      await this._handleAuthCallback();
    } catch (e) {
      if (e instanceof OauthClientError) {
        await this.login({ redirectUri });
      } else {
        throw e;
      }
    }
  }

  async login ({ redirectUri } = {} as { redirectUri?: string }) {
    this._clearAuthState();
    const { codeChallenge, verifier } = await getPkceCodeChallenge();
    const stateId = createUUID();
    const initialURL = redirectUri || window.location.href;
    const initialURLWithoutParams = trimAuthParams(initialURL);
    const callbackState = {
      id: stateId,
      verifier,
      initialURL,
    };
    this._storage.add(callbackState);
    const queryParams = new URLSearchParams({
      client_id: environment.REACT_APP_PASSPORT_CLIENT_ID!,
      // trim query params to prevent loops
      redirect_uri: initialURLWithoutParams,
      response_type: 'code',
      state: stateId,
      code_challenge: codeChallenge,
      code_challenge_method: 'S256',
    });

    this._pubsub.trigger(onBeforeLoginSymbol);

    const url = `https://${environment.REACT_APP_TENANT_API_URL}/oauth/authorize?${queryParams.toString()}`;
    // Redirect user to backend's auth form
    window.location.replace(url);
    // wait forever until window.location is loading
    await new Promise(resolve => {
      setTimeout(() => resolve(true), 60000);
    });
  }

  async logout ({ redirectUri } = {} as { redirectUri?: string }) {
    debugger
    this._clearAuthState();
    const queryParams = new URLSearchParams();
    const redirectUriWithFallback = redirectUri || window.location.origin;
    queryParams.append('redirect_uri', redirectUriWithFallback);
    const qsString = `?${queryParams.toString()}`;
    const url = `https://${environment.REACT_APP_TENANT_API_URL}/logout` + qsString;
    window.location.replace(url);
    // wait forever until window.location is loading
    await new Promise(resolve => {
      setTimeout(() => resolve(true), 60000);
    });
  }
  async refreshToken () {
    try {
      return await this._updateTokenDebounced();
    } catch (e) {
      if (e instanceof OauthClientError) {
        await this.login();
      } else {
        throw e;
      }
    }
  }

  async checkAuth () { // alias
    return this.refreshToken();
  }

  onTokenUpdate (cb: (token: Token) => void) {
    // @ts-ignore
    return this.__hookFactory(onTokenUpdateSymbol, cb);
  }

  onBeforeLogin (cb: () => void) {
    return this.__hookFactory(onBeforeLoginSymbol, cb);
  }
  // @ts-ignore
  _parseCallbackUrl () {
    const searchParams = new URLSearchParams(window.location.search);
    const [
      stateId,
      code,
      impersonation,
    ] = AUTH_PARAMS.map(k => searchParams.get(k));
    if ((stateId || impersonation) && code) {
      if (stateId) {
        const state = this._storage.get(stateId) as StoredCallbackState|undefined|null;
        if (!state) {
          throw new OauthClientError('Invalid state.');
        }
        this._oauthState = {
          ...state,
          code,
        };
      } else {
        // impersonation
        this._oauthState = {
          id: '',
          initialURL: window.location.origin,
          // @ts-ignore
          verifier: environment.VITE_AUTH_IMPERSONATION_VERIFIER,
          code,
        };
      }
      window.history.replaceState(null, '', this._oauthState.initialURL);
      return;
    }
    throw new OauthClientError('Invalid callback URL');
  }

  // @ts-ignore
  async _handleAuthCallback () {
    try {
      this._parseCallbackUrl();
      await this._getToken();
    } catch (e) {
      this._clearAuthState();
      throw e;
    }
  }

  // @ts-ignore
  async _getToken () {
    if (!this._oauthState) throw new OauthClientError('no oauth state');
    const initialURLWithoutParams = trimAuthParams(this._oauthState.initialURL);
    const resp = await fetch(`https://${environment.REACT_APP_TENANT_API_URL}/oauth/token`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: new URLSearchParams({
        grant_type: 'authorization_code',
        client_id: this.clientId,
        redirect_uri: initialURLWithoutParams,
        code_verifier: this._oauthState.verifier,
        code: this._oauthState.code,
      }),
    })
    const data = await resp.json() as BackendTokenJSON;
    const { accessToken } = this._setToken({
      accessToken: data.access_token,
      refreshToken: data.refresh_token,
      expiresIn: data.expires_in * 1000,
    });
    return accessToken;
  }

  // @ts-ignore
  async _updateToken (minValidity = 60) {
    if (!this.token) throw new OauthClientError('no token');
    const isTokenExpired = new Date().getTime() > (this.token.timestamp + this.token.expiresIn - (minValidity * 1000));
    if (isTokenExpired) {
      try {
        const resp = await fetch(`https://${environment.REACT_APP_TENANT_API_URL}/oauth/token`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          body: new URLSearchParams({
            grant_type: 'refresh_token',
            refresh_token: this.token?.refreshToken,
            client_id: this.clientId,
          }),
        })
        const data = await resp.json() as BackendTokenJSON;
        const { accessToken } = this._setToken({
          accessToken: data.access_token,
          refreshToken: data.refresh_token,
          expiresIn: data.expires_in * 1000,
        });
        return accessToken;
      } catch (e) {
        throw new OauthClientError('expired token');
      }
    }
    return this.token.accessToken;
  }

  // @ts-ignore
  _updateTokenDebounced = pdebounce(async () => this._updateToken());
  
  // @ts-ignore
  _setToken ({
    accessToken,
    refreshToken,
    expiresIn,
  }: Omit<Token, 'timestamp'>) {
    const token = this.token = {
      accessToken,
      refreshToken,
      expiresIn,
      timestamp: new Date().getTime(),
    };
    this._pubsub.trigger(onTokenUpdateSymbol, token);
    return token;
  }

  // @ts-ignore
  _clearAuthState () {
    this.token = null;
    this._oauthState = null;
    this._pubsub.trigger(onTokenUpdateSymbol, this.token);
  }

  // hooks
  // @ts-ignore
  __hookFactory (symbol: object, cb: (...args: unknown[]) => void) {
    this._pubsub.on(symbol, cb);
    return () => this._pubsub.off(symbol, cb);
  }
}

function b64Uri (string: string) {
  // https://tools.ietf.org/html/rfc4648#section-5
  return btoa(string).replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
}

// adapted from: https://raw.githubusercontent.com/coolgk/utils/master/oauth-pkce/src/index.ts
async function getPkceCodeChallenge (length?: number) {
  if (!length) length = 43;

  const verifier = b64Uri(
    Array.prototype.map
      .call(window.crypto.getRandomValues(new Uint8Array(length)), function (number) {
        return String.fromCharCode(number);
      })
      .join(''),
  ).substring(0, length);

  const randomArray = new Uint8Array(verifier.length);
  for (let i = 0; i < verifier.length; i++) {
    randomArray[i] = verifier.charCodeAt(i);
  }
  const digest = await window.crypto.subtle.digest('SHA-256', randomArray);
  return {
    verifier,
    codeChallenge: b64Uri(String.fromCharCode.apply(null, (new Uint8Array(digest) as unknown) as number[])),
  };
}
function createUUID () {
  const hexDigits = '0123456789abcdef';
  const s = generateRandomString(36, hexDigits).split('');
  s[14] = '4';
  // @ts-ignore
  s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1);
  s[8] = s[13] = s[18] = s[23] = '-';
  const uuid = s.join('');
  return uuid;
}

function generateRandomString (len, alphabet) {
  const randomData = window.crypto.getRandomValues(new Uint8Array(length));
  const chars = new Array(len);
  for (let i = 0; i < len; i++) {
    chars[i] = alphabet.charCodeAt(randomData[i] % alphabet.length);
  }
  return String.fromCharCode.apply(null, chars);
}
