/* eslint-disable */

export function pdebounce <T> (fn: () => Promise<T>): () => Promise<T> {
  let promise: null|Promise<T> = null;
  return async () => {
    if (!promise) {
      promise = fn();
      promise.finally(() => { promise = null; });
    }
    return promise;
  };
}