type AppEnvironment = {
    NODE_ENV: 'development' | 'production';
    REACT_APP_ENV: 'development' | 'staging' | 'production';

    REACT_APP_TENANT_API_URL?: string;
    REACT_APP_PASSPORT_CLIENT_ID?: string;

    REACT_APP_API_URL: string;
    REACT_APP_SUBSCRIPTION_URL: string;
    REACT_APP_CUBEJS_URL: string;
    REACT_APP_IMAGINARY_URL: string;
    REACT_APP_I18N_URL: string;

    REACT_APP_PORTAL_URL: string;
    REACT_APP_ONBOARD_URL: string;

    REACT_APP_SENTRY_DSN: string;

    REACT_APP_INTROSPECTION: boolean;
};

declare global {
    interface ImportMeta {
        readonly env: Partial<AppEnvironment>;
    }

    interface Window extends Partial<AppEnvironment> {
        readonly noOp: never;
    }
}

export const environment: AppEnvironment = {} as unknown as AppEnvironment;

environment.REACT_APP_ENV = (window.REACT_APP_ENV ||
    process.env.REACT_APP_ENV ||
    'production') as AppEnvironment['REACT_APP_ENV'];

environment.REACT_APP_API_URL =
    process.env.REACT_APP_API_URL || 'graphql.xrdm.app';
environment.REACT_APP_SUBSCRIPTION_URL =
    process.env.REACT_APP_SUBSCRIPTION_URL || 'graphql-subscriptions.xrdm.app';
environment.REACT_APP_PORTAL_URL =
    process.env.REACT_APP_PORTAL_URL || 'app.arborxr.com';
environment.REACT_APP_ONBOARD_URL =
    process.env.REACT_APP_ONBOARD_URL || 'onboard.xrdm.app';

environment.REACT_APP_TENANT_API_URL =
    process.env.REACT_APP_TENANT_API_URL || 'auth-api.xrdm.app';
environment.REACT_APP_PASSPORT_CLIENT_ID =
    process.env.REACT_APP_PASSPORT_CLIENT_ID;

environment.REACT_APP_CUBEJS_URL =
    process.env.REACT_APP_CUBEJS_URL || 'analytics.xrdm.app';
environment.REACT_APP_IMAGINARY_URL =
    process.env.REACT_APP_IMAGINARY_URL || 'images.xrdm.app';
environment.REACT_APP_I18N_URL =
    process.env.REACT_APP_I18N_URL || 'i18n.xrdm.app';

environment.REACT_APP_SENTRY_DSN =
    process.env.REACT_APP_SENTRY_DSN ||
    'https://60b38ed7c4e1462d911699003e69c432@o381374.ingest.sentry.io/5997920';

environment.REACT_APP_INTROSPECTION = (process.env.REACT_APP_INTROSPECTION ===
    'true' || false) as boolean;

Object.keys(window)
    .filter(key => key.startsWith('REACT_APP_'))
    .forEach(key => {
        environment[key] = window[key];
    });
