/* eslint-disable */

export class PubSub <T extends object> {
  pubsubMap = new WeakMap<T, Set<(...args: unknown[]) => void>>();

  on (obj:T, cb:() => void) {
    const set = this.pubsubMap.get(obj);
    if (set) {
      set.add(cb);
    } else {
      const set = new Set<(...args: unknown[]) => void>().add(cb);
      this.pubsubMap.set(obj, set);
    }
  }

  trigger (obj:T, ...args: unknown[]) {
    this.pubsubMap.get(obj)?.forEach((cb) => cb(...args)); // eslint-disable-line node/no-callback-literal
  }

  off (obj:T, cb:() => void) {
    const set = this.pubsubMap.get(obj);
    if (set) {
      set.delete(cb);
      if (set.size === 0) this.pubsubMap.delete(obj);
    }
  }
}
