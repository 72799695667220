import { client } from './auth-client';
import { environment } from './environment';
/* eslint-disable */
(async () => {
    await client.init();
    const token = await client.refreshToken();
    const currentUserQuery =
        'query currentUser {currentUser { hasAdminAccess fullName id } }';
    try {
        const jsonData = await fetch(
            `https://${environment.REACT_APP_API_URL}?query=${currentUserQuery}`,
            {
                method: 'GET',
                headers: new Headers({
                    Authorization: `Bearer ${token}`,
                }),
            }
        ).then(response => response.json());
        if (!jsonData.data.currentUser.hasAdminAccess) {
            client.logout();
        }
        const { hasAdminAccess, ...currentUser } = jsonData.data.currentUser;
        localStorage.setItem('currentUser', JSON.stringify(currentUser));
    } catch (e) {
        console.error(e);
        client.logout();
    }
    import('./main');
})();
