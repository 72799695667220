/* eslint-disable */

import type { StoredCallbackState } from './types';

// adapted from keycloak https://github.com/jonkoops/keycloak/blob/7aa3cb21e6bea6b72184620c651d9e1149ed93c7/js/libs/keycloak-js/src/keycloak.js
export class LocalStorage {
  KEY_PREFIX = 'kc-callback-';
  clearExpired () {
    const time = new Date().getTime();
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key && key.indexOf(this.KEY_PREFIX) === 0) {
        const value = localStorage.getItem(key);
        if (value) {
          try {
            const expires = JSON.parse(value).expires;
            if (!expires || expires < time) {
              localStorage.removeItem(key);
            }
          } catch (err) {
            localStorage.removeItem(key);
          }
        }
      }
    }
  }

  get (stateId: string) {
    if (!stateId) {
      return;
    }

    const key = this.KEY_PREFIX + stateId;
    let value = localStorage.getItem(key);
    if (value) {
      localStorage.removeItem(key);
      value = JSON.parse(value);
    }

    this.clearExpired();
    return value;
  }

  add (state: StoredCallbackState) {
    this.clearExpired();
    const key = this.KEY_PREFIX + state.id;
    const storedState = {
      ...state,
      expires: new Date().getTime() + (60 * 60 * 1000),
    };
    localStorage.setItem(key, JSON.stringify(storedState));
  }
}
